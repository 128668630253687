.zidoPendingUnit {
  position: fixed;
  bottom: 0;
  left: 0;
  cursor: pointer;
  width: 33%;
  max-width: 190px;
  z-index: 999999;
}

.zidoPendingUnit-bull {
  position: absolute;
  top: 0px;
  right: -20px;
  background: white;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 5px solid #f4580f;
  color: #f4580f;
  text-align: center;
  padding: 10px;
  transition: all 0.75s 0s ease-in-out;
}

.zidoPendingUnit-bull .sandclock {
  height: 25px;
}

.zidoPendingUnit-message {
  width: 400px;
  right: -400px;
  height: 100px;
  border-radius: 10px;
  top: -20px;
  overflow: hidden;
}
