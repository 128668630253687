.vjs-matrix .vjs-big-play-button,
.video-button {
  @apply m-0 w-24 h-24 lg:w-28 lg:h-28 rounded-full bg-transparent border-none;
}

.vjs-matrix .vjs-big-play-button,
.container__video-buttons {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

.video-js .vjs-control-bar {
  @apply h-10 sm:h-16 flex items-center;
}

.video-js .vjs-control.vjs-play-control {
  @apply rounded-full bg-white text-[#f6580f] shadow-2xl bottom-0 left-2 mr-2;
}

.video-js .vjs-play-control .vjs-icon-placeholder:before,
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  @apply text-3xl sm:text-4xl flex items-center justify-center;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: '';
  background-image: url('/public/images/v3/yellow-play-icon.svg');
  background-repeat: no-repeat;
  background-size: 70px;
  background-position: 55% calc(50% - 0px);
  border: none !important;
  box-shadow: none !important;
}

.video-js .vjs-control {
  @apply text-sm sm:text-lg w-10 h-10 sm:w-14 sm:h-14;
}
.video-js .vjs-time-control.vjs-control {
  @apply w-auto;
}

.video-js .vjs-mute-control .vjs-icon-placeholder:before {
  @apply flex items-center justify-center;
}

.video-js .vjs-volume-panel {
  @apply flex items-center;
}

.video-js .vjs-volume-level:before,
.video-js .vjs-play-progress:before {
  @apply text-[#f6580f] text-lg sm:text-2xl -top-3;
}

/* Commenting these lines because it is overriding the core css of videojs and having some issues with spacing if we want to remove some controllers */
/* .video-js .vjs-current-time {
  @apply pl-0 pr-0;
}

.video-js .vjs-duration {
  @apply pl-0;
}
.video-js .vjs-time-divider {
  @apply p-0 m-0;
} */

.video-js .vjs-time-control {
  @apply hidden sm:flex items-center justify-center;
}

.video-js .vjs-remaining-time {
  @apply block sm:hidden;
}

.video-js .vjs-picture-in-picture-control {
  @apply hidden sm:inline-block;
}

video[poster] {
  object-fit: cover;
}
.vjs-poster {
  background-size: cover;
  background-position: inherit;
}
