/* asmaa-regular - latin_arabic */
@font-face {
  font-family: 'Asmaa Font Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Asmaa.ttf') format('truetype'),
    url('../fonts/Asmaa.otf') format('opentype');
}
/* asmaa-regular - latin_arabic */
@font-face {
  font-family: 'Kidzhood Arabic DEMO';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/kidzhood.ttf') format('truetype');
}
/* cairo-regular - latin_arabic */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../fonts/cairo-v17-latin_arabic-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../fonts/cairo-v17-latin_arabic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* LHLamarPro-Regular-400 */
@font-face {
  font-family: 'LH Lamar Pro';
  src: url('../fonts/lamar-lh-regular.otf') format('opentype'),
    url('../fonts/lamar-lh-regular.otf') format('opentype'),
    url('../fonts/lamar-normal.otf') format('opentype'),
    url('../fonts/lamar-regular.otf') format('opentype');
}
/* cairo-700 - latin_arabic */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('../fonts/cairo-v17-latin_arabic-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../fonts/cairo-v17-latin_arabic-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Noto Sans Arabic Regular */
@font-face {
  font-family: 'Noto Sans Arabic';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/NotoSansArabic-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'FF Hekaya Light';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/FFHekaya-Light.otf') format('opentype');
}

/* ABeeZee-Regular-400 - latin_arabic */
@font-face {
  font-family: 'ABeeZee-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/ABeeZee-Regular.ttf') format('truetype');
}
