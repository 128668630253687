.RSPBprogressBar {
  height: 10px;
  line-height: 1;
  border-radius: 10px;
  position: relative;
  background-color: rgba(173, 216, 230, 0.6); /* lightgrey with alpha */
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
}

.RSPBprogressBar .RSPBstep {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: absolute;
  transform: translateX(50%);
  transition-property: all;
  transition-timing-function: ease;
}

.RSPBprogressBar .RSPBprogressBarText {
  color: white;
  font-size: 10px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, 50%);
}

.RSPBprogressBar .RSPBprogression {
  position: absolute;
  transition: width 0.3s ease;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background: rgba(0, 116, 217, 0.8); /* #0074d9 with alpha */
  z-index: -1;
}
