@tailwind base;
@tailwind components;
@tailwind utilities;

@import './resources/stylesheets/font-faces.css';
@import './styles/progressBar.css';

.widget-visible iframe {
  z-index: 10 !important;
}
img {
  pointer-events: none !important;
}

.react-date-picker__inputGroup__input,
.react-date-picker__inputGroup__input:focus,
.react-date-picker__inputGroup__divider {
  background-color: transparent !important;
  /* height: 20px !important; */
  border: 0px none !important;
  position: relative;
  top: 2px;
  right: 0px;
}
.react-date-picker__inputGroup {
  height: 20px !important;
}
.widget-visible {
  z-index: 101 !important;
}

.hours-swiper > .swiper {
  height: 56px;
}

/* .swiper {
  width: 100%;
  height: 378px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
.swiper-slide-next {
  margin-left: 60px;
}
.swiper-button-next,
.swiper-button-prev {
  left: -2px !important;
}
.swiper-button-next,
.swiper-button-prev {
  width: 38px;
  height: 38px;
  display: block;
} */
/* 
@media only screen and (min-width: 650px) {
  .swiper-button-next {
    left: 10% !important;
  }
  .swiper-button-prev {
    right: 10% !important;
  }
}

@media only screen and (min-width: 820px) {
  .swiper-button-next {
    left: 20% !important;
  }
  .swiper-button-prev {
    right: 20% !important;
  }
} */
.unchecked-notification {
  background: #eaf7fe !important;
}
div[data-testid='flowbite-tooltip'] {
  padding-left: 0px !important;
  padding-right: 0px !important;
  z-index: 100001 !important;
}
.swiper-pagination {
  bottom: 0px !important;
}
.swiper-button-prev:after {
  transform: rotate(180deg);
}

.swiper-slide {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* .swiper {
  width: 100%;
  height: 378px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
.swiper-slide-next {
  margin-left: 60px;
}
.swiper-button-next,
.swiper-button-prev {
  left: -2px !important;
}
.swiper-button-next,
.swiper-button-prev {
  width: 38px;
  height: 38px;
  display: block;
} */
/* 
@media only screen and (min-width: 650px) {
  .swiper-button-next {
    left: 10% !important;
  }
  .swiper-button-prev {
    right: 10% !important;
  }
}

@media only screen and (min-width: 820px) {
  .swiper-button-next {
    left: 20% !important;
  }
  .swiper-button-prev {
    right: 20% !important;
  }
} */
.swiper-pagination {
  bottom: 0px !important;
}
.swiper-button-prev:after {
  transform: rotate(0deg);
}
.swiper-button-next:after,
.swiper-button-prev:after {
  background-image: url('/public/images/v3/next-btn-enabled.svg');
  background-size: cover;
  font-family: 'sans-serif';
  content: '' !important;
  width: 100%;
  height: 100%;
  display: block;
}
.swiper-button-prev:after {
  background-image: url('/public/images/v3/prev-btn-enabled.svg');
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 1;
}
.swiper-button-next.swiper-button-disabled:after,
.swiper-button-prev.swiper-button-disabled:after {
  background-image: url('/public/images/v3/next-btn-disabled.svg');
  opacity: 1;
}
.swiper-button-prev.swiper-button-disabled:after {
  background-image: url('/public/images/v3/prev-btn-disabled.svg');
}

.swiper-slide {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.react-date-picker__inputGroup__input,
/* .react-date-picker__inputGroup__input,
.react-date-picker__inputGroup__divider {
  color: white;
}

@media (max-width: 1024px) { */
.react-date-picker__inputGroup__input,
.react-date-picker__inputGroup__divider {
  color: #515977;
}
/* } */

/*
need local files
theatre-bg-top.svg
bg-theatre.png
gallery-bg-bottom.png
map-bg.jpg
theatre-bg-left.svg
theatre-bg-right.svg
bg-activities.png
episode-bg.png
profile-bg.png
profile-bg-bottom.png
bg-orange@2x.jpg
bg-red@2x.png
bg-green@2x.jpg
bg-blue.png
bg-red.png
bg-green.jpg
bg-orange.jpg
bg-red@3x.png
bg-blue@3x.png
bg-green@3x.jpg
bg-orange@3x.jpg
*/
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@layer utilities {
  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .hide-scroll::-webkit-scrollbar {
    display: none;
  }

  .login-bg {
    background: url('./resources/images/login-bg.png') bottom center no-repeat;
    background-size: 100% 100%;
    max-width: 550px;
  }
  .bg-theatre-appbar {
    background: url('./resources/images/theatre-bg-top.svg') bottom center
      no-repeat;
    background-size: cover;
    background-color: transparent;
  }
  .bg-theatre {
    background: url('./resources/images/bg-theatre.png') bottom right no-repeat;
    background-color: #c8e6f7;
  }

  .bg-kids {
    background-color: #d9f6ff;
    background-size: 'contain';
    background-repeat: 'no-repeat';
  }

  .bg-v3 {
    background: url('/public/images/v3/bgoverlay.svg');
    background-repeat: repeat-y;
    background-size: cover;
    background-color: #263d99 !important;
  }
  .bg-green-v3 {
    background: linear-gradient(
        180deg,
        rgba(4, 87, 39, 0.68) 51.56%,
        rgba(4, 87, 39, 0.9) 100%
      ),
      linear-gradient(
        0deg,
        #045c2b 0%,
        rgba(4, 92, 43, 0.35) 20%,
        rgba(4, 92, 43, 0.47) 100%,
        rgba(4, 92, 43, 0.47) 100%
      ),
      url('/public/images/v3/bg-vector-overlay.svg');
    background-repeat: repeat-y;
    background-size: cover;
    background-color: #08773f !important;
  }

  .bg-disable-overlay {
    @apply before:absolute before:left-0 before:right-0 before:top-0 before:z-10 before:h-full before:w-full before:bg-black before:opacity-60 before:rounded-[20px];
  }

  .bg-dimming-overlay-\[image\:var\(--image-url\)\] {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0.2396)
      ),
      var(--image-url);
  }

  .bg-dimming-overlay-green-\[image\:var\(--image-url\)\] {
    background-image: linear-gradient(to top, #045c2b, rgba(0, 0, 0, 0.2396)),
      var(--image-url);
  }

  .bg-gallery {
    background-image: url('./resources/images/gallery-bg-bottom.png');
    background-position: bottom center;
    background-repeat: repeat-x;
  }

  .bg-home-page {
    background-image: url('./resources/images/map-bg.jpg');
  }

  .bg-theatre-sides {
    background: url('./resources/images/theatre-bg-left.svg') top left no-repeat
        fixed,
      url('./resources/images/theatre-bg-right.svg') top right no-repeat fixed;
    background-color: transparent;
  }
  .bg-activities {
    background-image: url('./resources/images/bg-activities.png');
  }

  .bg-episodes {
    background-image: url('./resources/images/episode-bg.png');
  }

  .bg-activity-thumb-blue {
    background: url('./resources/images/activity-blue-bg.png') center center
      no-repeat;
    background-size: contain;
  }
  .bg-activity-thumb-green {
    background: url('./resources/images/activity-green-bg.png') center center
      no-repeat;
    background-size: contain;
  }

  .bg-blue-card {
    background: url('./resources/images/blue-card.png') center center no-repeat;
    background-size: contain;
  }

  .bg-profile {
    background-image: url('./resources/images/profile-bg.png');
  }

  .bg-profile-bottom {
    background-image: url('./resources/images/profile-bg-bottom.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .bg-paid-popup {
    background-image: url('./resources/images/group_12171.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 300px;
    height: 300px;
  }

  .popup-title-v3,
  .popup-title-competition {
    outline: 8px solid rgba(0, 0, 0, 0.18);
  }
  .popup-title-v3:after,
  .popup-title-competition:after {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='11' ry='11' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 11px;
    background-repeat: no-repeat;
    width: 191px;
    right: 3px;
    top: 2px;
    height: 44px;
    content: '';
    position: absolute;
    opacity: 0.5;
  }
  .popup-title-competition:after {
    @apply w-[210px] lg:w-[312px] h-11 lg:h-[51px];
  }
  /* TODO: Find a popup background */
  .pencil-icon-v3 {
    position: absolute;
    top: calc(50% - 197px);
    left: calc(50% - 219px);
    transform: rotate(-9deg);
    z-index: 0;
  }
  .bg-icon-close-v3 {
    background: url('/public/images/v3/redCross-anime.svg') no-repeat;
    background-size: 40px 40px;
    width: 40px;
    height: 40px;
    display: block;
    position: absolute;
    top: -16px;
    right: 17px;
  }
  .bg-popup-v3,
  .bg-popup-competition {
    position: relative;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='50' ry='50' stroke='%23ffff' stroke-opacity='0.49' stroke-width='2' stroke-dasharray='6%2c 9' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    width: 334px;
    right: -5px;
    top: -6px;
    height: 253px;
    border-radius: 50px;
    color: white;
    box-sizing: border-box;
    margin: 0;
  }

  .bg-popup {
    background-image: url('./resources/images/group_12171.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 300px;
    height: 300px;
  }
  .bg-popup-competition {
    @apply w-[420px] h-[252px] lg:w-[631px] lg:h-[286px];
  }

  .bg-icon-close {
    background-image: url('./resources/images/group_11259.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 46px;
    width: 45px;
    display: block;
    position: absolute;
    top: 40px;
    right: 30px;
    cursor: pointer;
  }

  .icon-close-popup {
    background-image: url('./resources/images/group_11259.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 46px;
    width: 45px;
    display: block;
    position: absolute;
    cursor: pointer;
  }

  .submitButton {
    background: url('./resources/images/group_12286.png') center bottom
      no-repeat;
    background-size: contain;
  }

  .outlineButton {
    background: url('./resources/images/green-outline-button.png') center center
      no-repeat;
    background-size: contain;
    height: 100%;
  }

  .greenButton {
    background: url('./resources/images/group_12731.png') center center
      no-repeat;
    background-size: contain;
  }

  .editButton {
    background: url('./resources/images/group_12299.png') center bottom
      no-repeat;
    background-size: contain;
  }

  .passwordReveal {
    background: url('./resources/images/password-eye.svg') center bottom
      no-repeat;
    background-size: contain;
  }

  .lockIcon {
    background: url('./resources/images/lock-icon-purchase.png') center bottom
      no-repeat;
    background-size: contain;
  }

  .papperIcon {
    background: url('./resources/images/papper-icon.png') center bottom
      no-repeat;
    background-size: contain;
  }

  .loginWithGoogle {
    background: url('./resources/images/google-button.png') center no-repeat;
    background-size: contain;
  }
  .loginWithApple {
    background: url('./resources/images/apple-button.png') center no-repeat;
    background-size: contain;
  }
  .loginWithFacebook {
    background: url('./resources/images/facebook-login.png') center bottom
      no-repeat;
    background-size: contain;
  }
  .splash-orange {
    background-image: url('./resources/images/splash-orange.svg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bg-orange {
    background-image: url('./resources/images/bg-orange.jpg');
  }
  .bg-blue {
    background-image: url('./resources/images/group_12414.png');
  }
  .bg-blue-sky {
    background-image: url('./resources/images/home-bg-new.png');
  }
  .bg-level {
    background-image: url('./resources/images/group_12428.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .bg-epoisodes {
    background-image: url('./resources/images/group_8675.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bg-kid-list,
  .bg-package {
    background-image: url('./resources/images/group_12431.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    position: relative;
  }

  .bg-kid-add {
    background-image: url('./resources/images/group_12427.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    position: relative;
  }

  .bg-red {
    background-image: url('./resources/images/bg-red.png');
  }
  .bg-green {
    background-image: url('./resources/images/bg-green.jpg');
  }

  .bg-login-page {
    background: url('./resources/images/home_background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
  }

  .text-shadow {
    text-shadow: 0 2px rgba(0, 0, 0, 0.5);
  }

  .svg-icon-shadow {
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  }

  .button-inner-shadow {
    box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.22);
  }

  .bg-activity-button {
    background: linear-gradient(to bottom, #6dda4c 5%, #58c55a 100%);
    background-color: #6dda4c;
  }
  .bg-activity-button:hover {
    background: linear-gradient(to bottom, #58c55a 5%, #6dda4c 100%);
    background-color: #58c55a;
  }
  .bg-activity-button:active {
    top: 1px;
    box-shadow: inset -1px 4px 2px 0px #389a51;
  }

  .shadow-orange-buttons {
    box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.22);
  }

  .shadow-yellow-buttons {
    box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.22);
  }

  .shadow-modal-close {
    box-shadow: inset 0px -7px #d04b0c;
  }

  .shadow-modal-button {
    box-shadow: inset 0 -6px 0 0 #dece92;
  }

  .shadow-activity-button {
    box-shadow: -1px 4px 2px 0px #389a51;
  }

  .animate-fadein {
    animation: fadein 500ms;
  }

  .safe-area {
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
      env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  .bg-cyan-box {
    background-color: rgba(212, 247, 255, 1);
  }

  @screen sm {
    .bg-level {
      height: 270px;
    }
  }

  @screen md {
    .bg-theatre {
      background: url('./resources/images/theatre-bg-left.svg') top left
          no-repeat fixed,
        url('./resources/images/theatre-bg-right.svg') top right no-repeat fixed,
        url('./resources/images/bg-theatre.png') bottom fixed no-repeat,
        url('./resources/images/bg-theatre-repeat.png') bottom fixed repeat-x;
      background-color: #c8e6f7;
    }
    .bg-level {
      height: 300px;
    }
  }

  @screen lg {
    .bg-orange {
      background-image: url('./resources/images/bg-orange@2x.jpg');
    }
    .bg-blue {
      background-image: url('./resources/images/group_12414.png');
    }
    .bg-blue-sky {
      background-image: url('./resources/images/home-bg-new.png');
    }
    .bg-level {
      background-image: url('./resources/images/group_12428.png');
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 390px;
    }
    .bg-red {
      background-image: url('./resources/images/bg-red@2x.png');
    }
    .bg-green {
      background-image: url('./resources/images/bg-green@2x.jpg');
    }
  }

  @screen 2xl {
    .bg-orange {
      background-image: url('./resources/images/bg-orange@3x.jpg');
    }
    .bg-blue {
      background-image: url('./resources/images/group_12414xxh.png');
    }
    .bg-blue-sky {
      background-image: url('./resources/images/home-bg-new.png');
    }
    .bg-level {
      background-image: url('./resources/images/group_12428.png');
      height: inherit;
      background-position: center center;
    }
    .bg-red {
      background-image: url('./resources/images/bg-red@3x.png');
    }
    .bg-green {
      background-image: url('./resources/images/bg-green@3x.jpg');
    }
  }
  .widget-visible .custom-positions {
    bottom: 40px !important;
    right: 20px !important;
  }
  @media only screen and (max-width: 1280px) {
    .widget-visible .custom-positions {
      bottom: 40px !important;
      right: 20px !important;
    }
  }
}

@layer components {
  .input {
    @apply px-4 py-2 w-full rounded-full outline-none focus:ring-blue-400 focus:ring-4;
  }

  .btn-positive {
    @apply bg-button-200 bg-kids-buttons border-b-4 border-t-4 ml-4 border-b-button-100 border-t-button-100
    font-bold w-full h-12 rounded-full text-white text-[15px]
    disabled:grayscale;
  }
}

@layer base {
  body {
    margin: 0;
    padding: 0;
    @apply font-body;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body.modal-open {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
  }
  @screen lg {
    body.modal-open {
      padding-right: 15px;
    }
  }
}

.appbar-fixed + * {
  @apply pt-[67px] sm:pt-[90px] md:pt-[105px];
}

.rc-pagination-prev,
.rc-pagination-next {
  @apply focus:ring mx-2 w-16 border border-gray-400 hover:bg-black hover:bg-opacity-10 rounded-lg;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}
.bounce {
  animation-name: bounce;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.animate-float {
  animation: float 4s ease-in-out infinite;
}

.PhoneInputCountrySelectArrow {
  margin-right: 10px;
}

.react-date-picker__wrapper {
  border: 0 none;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  color: black !important;
  border: black 1px solid;
}

.keyboard-offset {
  padding-bottom: 219px; /* Keyboard height */
}

.swiper-pagination-bullet-active {
  border: 2px solid #0c9be2 !important;
  background-color: #d9f6ff !important;
}

/* competition */
/* define the styles for the custom tab */

.tabs > .tabs {
  @apply bg-white w-[227px] rounded-2xl flex px-1.5 py-1.5 mx-auto gap-x-1;
}
.tabs > .tabs > button {
  @apply rounded-2xl text-2xl w-[100px] text-center leading-7 p-0 flex-1 ml-0;
}
.tabs > .tabs > :not([hidden]) ~ :not([hidden]) {
  @apply ml-0 mr-0;
}

/* Define the styles for the custom scrollbar */
.v3-scrollbar {
  scrollbar-color: #ffffffb8; /* Change these colors as needed */
  scrollbar-width: thin;
}

/* Define the styles for Webkit-based browsers (e.g., Chrome, Safari) */
.v3-scrollbar::-webkit-scrollbar {
  @apply w-2;
}

.v3-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ffffffb8; /* Change this color as needed */
  border-radius: 6px;
}

.v3-scrollbar::-webkit-scrollbar-track {
  background-color: transparent; /* Change this color as needed */
}

.v3-shadow {
  width: inherit !important;
  height: inherit !important;
  /* position: relative; */
  z-index: 2;
}
.v3-shadow:after {
  content: '';
  position: absolute;
  z-index: -10;
  width: 98%;
  height: 98%;
  bottom: -4px;
  left: 4px;
  @apply rounded-full;
}

.v3-shadow-green:after {
  background: #5e7425 !important;
}
.v3-shadow-blue:after {
  background: #4399cd !important;
}
.v3-icon-green:after {
  background: #8bb425 !important;
}
.v3-icon-red:after {
  background: #dd251f !important;
}
.v3-icon-orange:after {
  background: #c5711d !important;
}
.v3-icon-purple:after {
  background: #5349b5 !important;
}
.v3-icon-blue:after {
  background: #4399ce !important;
}
.v3-icon-sky:after {
  background: #50b5a9 !important;
}
.bg-parent-add-kid:before {
  background: url('/public/images/v3/plusBtn.svg');
}
.bg-parent-gateway-v3:before {
  background: url('/public/images/v3/exitBtn.svg');
}
.bg-parent-course-button-v3:before {
  background: url('/public/images/v3/closedBookBtn.svg');
}
.bg-notification-button-v3:before {
  background: url('/public/images/v3/bellBtn.svg');
}
.bg-list-kids-v3:before {
  background: url('/public/images/v3/parentBtn.svg');
}
.bg-list-menu-v3:before {
  background: url('/public/images/v3/menuBtn.svg');
}
.v3-back-button:after {
  background: #75a012 !important;
}
.v3-back-button:before {
  background: url('/public/images/v3/backBtn.svg');
}

.v3-icon {
  width: 50px;
  height: 45px;
  position: relative;
  margin-right: 5px;
  margin-left: 5px;
}
.v3-icon:before {
  content: '';
  border-radius: 20px;
  width: 50px;
  height: 45px;
  z-index: 50;
  background-size: 50px 45px;
  position: absolute;
  display: block;
}
.v3-icon:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  border-radius: 20px;
  background: red;
  right: -2px;
  bottom: -3px;
  width: 50px;
  height: 45px;
}

@media only screen and (max-width: 1024px) {
  .v3-icon,
  .v3-icon:before,
  .v3-icon:after {
    width: 35px !important;
    height: 30px !important;
    background-size: 35px 30px !important;
  }
  .v3-icon:after {
    right: -1px !important;
    bottom: -2px !important;
  }
}

/* ANIMATIONS */

/* FADE IN UP TEXT */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-custom-fadeInUp {
  animation: fadeInUp 1s ease-in;
}
